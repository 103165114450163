@mixin row($indents: 12) {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -#{$indents}px;

  /* stylelint-disable-next-line */
  & > * {
    padding: 0 #{$indents}px;
  }
}

@mixin col-width($width) {
  flex: 0 0 $width;
  max-width: $width;
}

@mixin make-col($col, $max-cols: 12) {
  @include col-width(100% / $max-cols * $col);
}
