/* roboto-400 - latin_cyrillic */
@font-face {
  font-weight: 400;
  font-family: 'Roboto';
  font-style: normal;
  src: /* IE9 Compat Modes */ url('../assets/fonts/Roboto-latin_cyrillic-regular.eot');
  src: local(''), /* IE6-IE8 */ url('../assets/fonts/Roboto-latin_cyrillic-regular.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-regular.woff2') format('woff2'),
    /* Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-regular.woff') format('woff'),
    /* Safari, Android, iOS */ url('../assets/fonts/Roboto-latin_cyrillic-regular.ttf') format('truetype');
}

/* roboto-500 - latin_cyrillic */
@font-face {
  font-weight: 500;
  font-family: 'Roboto';
  font-style: normal;
  src: /* IE9 Compat Modes */ url('../assets/fonts/Roboto-latin_cyrillic-500.eot');
  src: local(''), /* IE6-IE8 */ url('../assets/fonts/Roboto-latin_cyrillic-500.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-500.woff2') format('woff2'),
    /* Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-500.woff') format('woff'),
    /* Safari, Android, iOS */ url('../assets/fonts/Roboto-latin_cyrillic-500.ttf') format('truetype');
}

/* roboto-700 - latin_cyrillic */
@font-face {
  font-weight: 700;
  font-family: 'Roboto';
  font-style: normal;
  src: /* IE9 Compat Modes */ url('../assets/fonts/Roboto-latin_cyrillic-700.eot');
  src: local(''), /* IE6-IE8 */ url('../assets/fonts/Roboto-latin_cyrillic-700.eot?#iefix') format('embedded-opentype'),
    /* Super Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-700.woff2') format('woff2'),
    /* Modern Browsers */ url('../assets/fonts/Roboto-latin_cyrillic-700.woff') format('woff'),
    /* Safari, Android, iOS */ url('../assets/fonts/Roboto-latin_cyrillic-700.ttf') format('truetype');
}
