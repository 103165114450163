@import 'variables';

.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100vw;
  height: 100vh;

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background: $color-dark;
    cursor: pointer;
    opacity: 0.7;
  }

  &__content {
    z-index: 20;
  }
}

.popup--opened {
  display: block;
  animation: fadeIn 0.2s linear 0s;
  animation-fill-mode: forwards;

  .popup {
    &__content {
      animation: scaleIn 0.4s cubic-bezier(0.25, 1, 0.5, 1) 0s;
      animation-fill-mode: forwards;
    }
  }
}
