/* Colors */

$color-red: #e30513;
$color-dark-red: #9a150e;
$gradient-red: linear-gradient(180deg, $color-red 0%, $color-dark-red 100%);
$color-red-transparent: rgba(
  $color: $color-red,
  $alpha: 0.1,
);
$color-orange: #f09a1d;
$color-green: #6cc85d;
$color-green-transparent: rgba(
  $color: $color-green,
  $alpha: 0.1,
);
$color-dark: #000;
$color-dark-grey: #706f67;
$color-grey: #a19f99;
$color-light-grey: #e0deda;
$color-white: #fff;
$color-bg: #f5f4f2;

$disabled-color: rgba(0, 0, 0, 0.25);
$placeholder-color: #c9c5be;

/* Shadow */

$default-shadow: 0 6px 16px rgba(171, 167, 149, 0.3);

/* Fonts */

$font-family-base: 'Roboto', sans-serif;

$font-size-largest: 22px;
$font-height-largest: 1.5;

$font-size-larger: 20px;
$font-height-larger: 1.5;

$font-size-large: 16px;
$font-height-large: 1.5;

$font-size-medium: 13px;
$font-height-medium: 1.54;

$font-size-smaller: 11px;
$font-height-smaller: 1.45;

$font-size-small: 10px;
$font-height-small: 1.2;

$font-size-minimal: 9px;
$font-height-minimal: 1.2;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$letter-spacing-base: 0.03em;

/* Responsive breakpoints */

$media-xs: 360px;
$media-sm: 640px;
$media-md: 1280px;
$media-lg: 1920px;

/* Scroll */

$scroll-color: #bbc1c7;
