@import 'mixins/font-mixins';
@import 'variables';

html {
  scroll-behavior: smooth;
}

html,
body {
  @include font-medium();
  height: 100%;
  color: $color-dark;
  font-family: $font-family-base;
  background-color: $color-bg;
  -webkit-font-smoothing: antialiased;
}

a {
  color: $color-grey;
  text-decoration: none;

  &:hover {
    color: $color-red;
    cursor: pointer;
  }
}

h1 {
  @include font-h1();
  padding-top: 59px;
  padding-bottom: 52px;
}

svg {
  vertical-align: text-top;
  stroke: currentColor;
}

.loading {
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: inherit;
    opacity: 0.7;
    content: '';
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 20;
    display: inline-block;
    width: 22px;
    min-width: 22px;
    height: 22px;
    margin-top: -10px;
    margin-left: -10px;
    border-top: 3px solid #000;
    border-right: 3px solid transparent;
    border-bottom: 3px solid #000;
    border-left: 3px solid #000;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
    content: '';
  }
}

.hidden {
  display: none !important;
}
