@import 'variables';

@mixin font-h1() {
  font-size: $font-size-largest;
  line-height: $font-height-largest;
}

@mixin font-larger() {
  font-size: $font-size-larger;
  line-height: $font-height-larger;
}

@mixin font-large() {
  font-size: $font-size-large;
  line-height: $font-height-large;
}

@mixin font-medium() {
  font-size: $font-size-medium;
  line-height: $font-height-medium;
}

@mixin font-smaller() {
  font-size: $font-size-smaller;
  line-height: $font-height-smaller;
}

@mixin font-small() {
  font-size: $font-size-small;
  line-height: $font-height-small;
}

@mixin font-minimal() {
  font-size: $font-size-minimal;
  line-height: $font-height-minimal;
}

@mixin font-header() {
  @include font-large();
  font-weight: $font-weight-bold;
}

@mixin font-medium-bold() {
  @include font-medium();
  font-weight: $font-weight-bold;
}

@mixin font-label() {
  @include font-smaller();
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin font-subtext() {
  @include font-smaller();
}

@mixin font-subtext-bold() {
  @include font-smaller();
  font-weight: $font-weight-bold;
}

@mixin font-counter() {
  @include font-small();
  font-weight: $font-weight-bold;
}

@mixin font-truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
