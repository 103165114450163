@import 'variables';
@import 'mixins/font-mixins';
@import 'mixins/list-mixins';

.navigation-list {
  @include list-unstyled();
  display: flex;
  flex-direction: column;
  height: 100%;

  &--inline {
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
  }

  &__link {
    @include font-medium-bold();
    position: relative;
    display: flex;
    align-items: center;
    padding: 16px 0;
    letter-spacing: $letter-spacing-base;

    &-icon {
      color: $color-light-grey;
      line-height: 1;
    }

    &-text {
      margin-left: 16px;
    }

    &--active,
    &:hover {
      color: $color-red;
    }

    &--active::before {
      position: absolute;
      top: 16px;
      left: -24px;
      width: 3px;
      height: 20px;
      background: currentColor;
      content: '';
    }

    &--no-icon {
      padding: 8px 0;
    }

    &--no-icon:first-child {
      padding-top: 16px;
    }

    &--dark {
      color: $color-dark;
    }
  }
}

.navigation-list__link--active,
.navigation-list__link:hover {
  .navigation-list__link-icon,
  .navigation-list__link-text {
    color: currentColor;
  }
}
