@import 'variables';
@import 'mixins/font-mixins';
@import 'mixins/grid-mixins';
@import 'mixins/media-mixins';

.block-wrapper {
  &--upsert-block {
    padding: 32px 32px 16px;
  }

  &--display-block {
    padding: 24px;
  }

  &--filters-block {
    margin-bottom: 35px;
    padding: 24px;
  }
}

.block {
  position: relative;
  width: 100%;

  &__details-header {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0;
    border-bottom: 1px solid $color-bg;

    &-row {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 24px 24px 0;
      border-bottom: 1px solid $color-bg;
    }
  }

  &__details-content {
    padding: 24px 0 0 24px;
  }

  &__section {
    width: 100%;
    padding-bottom: 25px;
    border-bottom: 1px solid $color-bg;

    &:not(:first-child) {
      padding-top: 45px;
    }

    &:last-child {
      border-bottom: 0;
    }

    &--strait {
      padding-bottom: 20px;

      &:not(:first-child) {
        padding-top: 25px;
      }
    }

    &--wide {
      padding-bottom: 45px;
    }

    &--without-border {
      border-bottom: 0;
    }
  }

  &__row {
    @include row();
    position: relative;
    margin-bottom: 25px;

    &--column-directed {
      flex-direction: column;
    }

    &--centered {
      align-items: center;
    }

    &--justified {
      justify-content: space-between;
    }

    &--compact {
      margin-bottom: 0;
    }

    &--strait {
      margin-bottom: 10px;
    }

    &--narrowed {
      margin-bottom: 15px;
    }

    &--widened {
      margin-bottom: 35px;
    }

    &--wide {
      margin-bottom: 45px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__column {
    @include make-col(6);
    position: relative;

    @include media-down($media-sm) {
      @include make-col(12);
    }

    &--large {
      @include make-col(12);
    }

    &--longer {
      @include make-col(11);
    }

    &--long {
      @include make-col(9);
    }

    &--enlarged {
      @include make-col(8);
    }

    &--compressed {
      @include make-col(5);
    }

    &--shortened {
      @include make-col(4);
    }

    &--short {
      @include make-col(3);
    }

    &--tiny {
      @include make-col(2);
    }

    &--minimal {
      @include make-col(1);
    }

    &--irreducible {
      @include make-col(0);
    }

    &--inline {
      display: flex;
      flex-direction: row;

      @include media-down($media-sm) {
        flex-direction: column;
        margin-right: 0;
      }

      *:not(:last-child) {
        margin-right: 5px;
      }
    }

    &--indented {
      margin-top: 10px;
    }

    &--no-padding {
      padding: 0;
    }

    &--no-overflow {
      overflow: hidden;
    }
  }

  &__heading {
    @include font-h1();
    padding: 40px 0;
    color: $color-dark;

    &--compact {
      margin-bottom: 0;
      padding-bottom: 24px;
    }
  }

  &__title,
  &__subtitle,
  &__label {
    width: 100%;

    &--autowidth {
      width: auto;
    }
  }

  &__title {
    @include font-header();
    margin: 0 0 20px;

    &--without-margins {
      margin: 0;
    }
  }

  &__subtitle {
    @include font-medium-bold();
    margin: 0 0 15px;

    @include media-down($media-sm) {
      margin-top: 20px;
    }

    &--inline {
      display: inline-block;
      margin-right: 40px;
    }
  }

  &__label {
    @include font-smaller();
    margin: 0 0 8px;
    color: $color-dark-grey;
    font-weight: $font-weight-normal;

    &--without-margins {
      margin: 0;
    }
  }

  &__text {
    @include font-medium();
    display: block;
    margin: 0 0 10px;
    overflow-wrap: break-word;

    &--compact {
      margin: 0 0 5px;
    }

    &--error {
      color: $color-red;
    }

    &--with-icon {
      display: flex;
      align-items: flex-start;
      line-height: $font-height-small;
    }

    &--with-icon:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__subtext {
    @include font-subtext();
    margin: 0 0 10px;
    color: $color-grey;
    font-weight: $font-weight-normal;

    &--error {
      color: $color-red;
    }
  }

  &__link {
    @include font-medium-bold();
    margin: 0;
    color: $color-grey;

    &--heading {
      padding-top: 12px;
    }
  }

  &__interval-separator {
    position: absolute;
    top: 36px;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media-down($media-sm) {
      display: none;
    }

    &--aligned {
      top: 16px;
    }
  }

  &__validation-icon {
    padding-left: 5px;
    color: $color-grey;
    line-height: 1;

    &--tip {
      color: $color-light-grey;
    }

    &--error {
      color: $color-red;
    }

    &--done {
      color: $color-green;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    &--start {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &--center {
      align-items: center;
      justify-content: center;
    }

    &--end {
      align-items: flex-end;
      justify-content: flex-end;
    }

    &--start-aligned {
      align-items: flex-start;
    }

    &--center-aligned {
      align-items: center;
    }

    &--end-aligned {
      align-items: flex-end;
    }

    &--start-justified {
      justify-content: flex-start;
    }

    &--center-justified {
      justify-content: center;
    }

    &--end-justified {
      justify-content: flex-end;
    }
  }

  &__buttons--start-justified {
    .block__button:last-child {
      margin-left: 0;
    }
  }

  &__button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
      margin-left: auto;
    }
  }

  &__icon {
    margin-right: 10px;
    color: $color-grey;
  }

  &--negative-indent {
    margin: 0 -12px;
  }

  &--static {
    position: static;

    * {
      position: static;
    }
  }
}
