@import 'variables';
@import 'mixins/font-mixins';

.tooltip {
  @include font-small();
  position: absolute;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
  min-height: 30px;
  padding: 4px 8px;
  color: $color-dark-grey;
  white-space: pre-line;
  background: $color-white;
  border-radius: 10px;
  box-shadow: $default-shadow;
  opacity: 0;

  &::after {
    position: absolute;
    border-style: solid;
    content: '';
  }

  &--visible {
    opacity: 1;
    animation: fadeIn 0.3s ease-in;
  }

  &--top::after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: $color-white transparent transparent transparent;
    border-width: 5px;
  }

  &--bottom::after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: transparent transparent $color-white transparent;
    border-width: 5px;
  }

  &--left::after {
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border-color: transparent transparent transparent $color-white;
    border-width: 5px;
  }

  &--right::after {
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-color: transparent $color-white transparent transparent;
    border-width: 5px;
  }

  &--follow::after {
    display: none;
  }
}
