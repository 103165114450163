@import 'variables';
@import 'mixins/font-mixins';

.details {
  &__wrapper {
    min-height: 100vh;
  }

  &__header {
    position: relative;
    top: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    padding: 20px 24px 0;
    background: $color-white;
    border-bottom: 1px solid $color-bg;
    transition: all 0.3s;

    &--sticky {
      position: sticky;
      box-shadow: $default-shadow;
    }
  }

  &__content {
    padding: 24px 12px 0 24px;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 15px;

    &--start {
      align-items: flex-start;
      justify-content: flex-start;
    }

    &--indented {
      padding-top: 15px;
    }

    &--widened {
      margin-bottom: 35px;
    }

    &--wide {
      margin-bottom: 45px;
    }
  }

  &__column {
    @include make-col(6);
    position: relative;

    @include media-down($media-sm) {
      @include make-col(12);
    }

    &--short {
      @include make-col(3);
    }
  }

  &__status-title-container {
    display: flex;
    padding-top: 4px;
  }

  &__title {
    @include font-large();
    max-width: 180px;
    margin: 0 20px 0 0;
    font-weight: $font-weight-bold;
  }

  &__subtitle {
    @include font-medium();
    margin: 0 0 10px;
    font-weight: $font-weight-bold;
  }

  &__label {
    @include font-smaller();
    margin: 0 0 8px;
    color: $color-dark-grey;
    font-weight: $font-weight-normal;
  }

  &__text {
    @include font-medium();
    font-weight: normal;
  }

  &__status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 70px;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: $default-shadow;
  }

  &__status {
    margin-right: auto;
  }

  &__buttons {
    display: flex;
    margin-left: auto;
    padding-left: 20px;
  }

  &__button {
    display: flex;
    align-items: center;
  }

  &__button + &__button {
    margin-left: 25px;
  }

  &__button + &__button--secondary {
    margin-left: 16px;
  }
}
