@import 'variables';
@import 'mixins/font-mixins';

.hidden-overflow {
  overflow: hidden !important;
}

.unset-overflow {
  overflow: initial !important;
}

.cell-divider {
  display: inline-block;
  width: 1px;
  height: 100%;
  margin-right: 30px;
  background: $color-bg;
}

.empty-message,
.error-message {
  @include font-smaller();
  font-weight: $font-weight-bold;
  text-align: center;

  &--left-align {
    text-align: left;
  }

  &--right-align {
    text-align: left;
  }

  &--medium {
    @include font-medium();
  }

  &--large {
    @include font-large();
  }
}

.empty-message {
  color: $color-grey;
}

.error-message {
  color: $color-red;
}

.unspecified-text {
  @include font-medium();
  margin: 0 0 5px;
  color: $color-grey;

  &--bold {
    color: $color-dark-red;
    font-weight: $font-weight-bold;
    letter-spacing: 0.03em;
  }
}
